var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"color":"rgba(255,255,255,0.9)"}},[(_vm.isChartLoaded)?_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"display-flex justify-space-between align-center mt-3"},[_c('p',{staticClass:"font-bold justify-start ml-4 mb-2",staticStyle:{"color":"rgba(255,255,255,0.9)","margin-top":"50px"}},[_vm._v(" Production "),_c('t-toggle',{class:_vm.showWellEvents ? 'toggle-on': 'toggle-off',staticStyle:{"float":"right","margin-right":"10px"},style:(_vm.showWellEvents ? 'background: transparent; border: 1px solid #20bdb0;' : 'background: transparent; border: 1px solid white;'),model:{value:(_vm.showWellEventsLocal),callback:function ($$v) {_vm.showWellEventsLocal=$$v},expression:"showWellEventsLocal"}}),_c('span',{staticClass:"justify-start inline-block text-sm mr-5 mt-0 ",staticStyle:{"color":"rgba(255,255,255,0.9)","float":"right"}},[_vm._v(" Show well events ")])],1)]),_c('div',{staticStyle:{"display":"inline-block","width":"20px","height":"270px"}},[_c('vue-slider',{staticStyle:{"display":"inline-block","margin-bottom":"60px","margin-top":"50px","margin":"30px 0","width":"2px","float":"right","height":"230px"},attrs:{"min":_vm.minYaxis,"direction":"btt","tooltip-placement":"right","min-range":10,"fixed":_vm.fixedAxis,"drag-on-click":_vm.fixedAxis,"clickable":_vm.fixedAxis,"enable-cross":false,"max":_vm.maxYaxis},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var value = ref.value;
var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }],on:{"mousedown":function($event){_vm.fixedAxis = false}}})]}},{key:"process",fn:function(ref){
var start = ref.start;
var end = ref.end;
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process custom-class",staticStyle:{"color":"white","background":"transparent","width":"70px"},style:([style])},[_c('span',{staticStyle:{"height":"calc(100% - 10%)","display":"inline-block","min-width":"30px","color":"white","margin-left":"-15px","margin-top":"20%","margin-bottom":"20%"},on:{"mousedown":function($event){_vm.fixedAxis = true}}})])]}}],null,false,3441216933),model:{value:(_vm.yAxisRange),callback:function ($$v) {_vm.yAxisRange=$$v},expression:"yAxisRange"}})],1),(!_vm.dataUpdating)?_c('line-chart',{ref:"chart",staticClass:"mt-2 mb-2",staticStyle:{"height":"280px","color":"white","display":"inline-block","width":"calc(100% - 31px)"},attrs:{"chart-data":_vm.chartData,"zoom-updated":_vm.zoomUpdated,"options":_vm.chartOptions}}):_vm._e(),_c('vue-slider',{staticStyle:{"display":"inline-block","height":"2px","margin-left":"50px","margin-right":"50px","width":"calc(100% - 71px)"},attrs:{"tooltip-placement":['bottom','bottom'],"data":_vm.chartData.labels,"enable-cross":false,"drag-on-click":_vm.fixedAxis,"clickable":_vm.fixedAxis,"fixed":_vm.fixedAxis,"tooltip-formatter":function (val) { return _vm.formatXaxis(val); }},on:{"drag-end":_vm.xAxisDragEnd},scopedSlots:_vm._u([{key:"dot",fn:function(ref){
var value = ref.value;
var focus = ref.focus;
return [_c('div',{class:['custom-dot', { focus: focus }],on:{"mousedown":function($event){_vm.fixedAxis = false}}})]}},{key:"process",fn:function(ref){
var start = ref.start;
var end = ref.end;
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process custom-class",staticStyle:{"color":"white","background":"transparent","height":"70px margin-top: -20px"},style:([style]),on:{"click":[function($event){$event.stopPropagation();},function($event){$event.preventDefault();}]}},[_c('span',{staticStyle:{"width":"calc(100% - 20%)","z-index":"9999999","margin-top":"-20px","display":"inline-block","min-height":"30px","margin-left":"10%","margin-right":"10%","color":"white"},on:{"mousedown":function($event){_vm.fixedAxis = true},"mouseup":function($event){_vm.fixedAxis = false},"click":[function($event){$event.stopPropagation();},function($event){$event.preventDefault();}]}})])]}}],null,false,3845074594),model:{value:(_vm.xAxisRange),callback:function ($$v) {_vm.xAxisRange=$$v},expression:"xAxisRange"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }