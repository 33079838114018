
























































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import { CHART_CLICK_EVENT, TASQ_OFF_TARGET_TYPE, WELL_CLICK_EVENT } from '@/lib/constants';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getConfigEnv } from '@/utils/helpers';
import workflowModule from '@/store/modules/workflowModule';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
import FuzzySearch from 'fuzzy-search';
import { Debounce } from 'vue-debounce-decorator';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
    VueSlider,
  },
})
export default class TasqProductionDataChart extends Vue {
  highlightChart = false;

  dataUpdating = false;

  showWellEventsLocal = false;

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get isChartLoaded() {
    return this.productionData?.loaded;
  }

  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }

  get chartFromTime() {
    return tasqsListModule.chartFromTime;
  }

  history: any = []

  formatXaxis(val) {
    return new Date(val).toLocaleString();
  }

  mouseLeaveRange() {
    setTimeout(() => {
      this.fixedAxis = true;
    }, 1500);
  }

  yAxisRange: any =['0', '80'];

   xAxisRange =['', ''];

   minYaxis: any = 1;

   zoomUpdated = 0;

   maxYaxis: any = 100;

   fixedAxis = false;

   created() {
     this.history = this.getWellHistory;
     if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
       this.chartData.datasets.splice(6, 1);
       this.chartData.datasets.splice(6, 1);
       this.chartData.datasets.splice(6, 1);
     }
     this.$eventBus.$on('HIGHLIGHT_CHART', (val) => {
       this.highlightChart = val;
     });
     this.$eventBus.$on('history-search-updated', (history) => {
       this.onWellHistoryUpdated(history);
       // console.log(result);
     });

     this.showWellEventsLocal = this.showWellEvents;

     this.$eventBus.$on('x-axis-drag-end', (xAxisRange) => {
       const dateHours = xAxisRange.map((s) => s.split('T'));

       // console.log(dateHours);

       // console.log(this.chartData.labels);
       // console.log( this.signalData.time);

       const start = this.chartData.labels.find((l) => l.includes(dateHours[0][0]));
       const end = this.chartData.labels.find((l) => l.includes(dateHours[1][0]));

       if (end && start) {
         this.xAxisRange = [end, start];
         Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
           min: start,
           max: end,
           maxTicksLimit: 20,

         });
       }

       this.zoomUpdated += 1;
     });
   }

  chartData: any = {
    datasets: [
      {
        label: 'Oil Rate',
        data: [],
        borderColor: '#2CE6C2',
		    backgroundColor: '#2CE6C2',
        // backgroundColor: 'rgba(44,230,194,0.0)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#666A96',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#666A96',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: 'Water rate',
        data: [],
        borderColor: '#0076FF',
        backgroundColor: 'rgba(0, 118, 255, 0.095)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#0076FF',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#0076FF',
        yAxisID: 'y-axis-1',
        spanGaps: true,
      },
      {
        label: 'Gas rate',
        data: [],
        borderColor: '#f55d8b',
        backgroundColor: '#f55d8b',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverRadius: 3,
        pointBorderColor: '#2CE6C2',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        pointBackgroundColor: '#2CE6C2',
        yAxisID: 'y-axis-0',
        spanGaps: true,
      },
      // {
      //   label: '',
      //   data: [],
      //   borderColor: '#6f00c1',
      //   backgroundColor: 'rgba(110,0,193,0.5)',
      //   tension: 0,
      //   borderWidth: 2,
      //   fill: false,
      //   pointRadius: 0,
      //   pointHitRadius: 5,
      //   borderDash: [10, 15],
      //   yAxisID: 'y-axis-0',
      //   spanGaps: true,
      // },
      {
        label: 'Target reference',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)', // Oil color
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target reference',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)', // Oil
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
    ],
    labels: [],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['mousemove', 'touchstart', 'touchmove', 'click'],
    legend: {
      display: true,
      position: 'top',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [{
        type: 'time',
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: '#ffffff90',
          fontStyle: 'bold',
          maxTicksLimit: 8,
          minRotation: 50,
          max: new Date(),
          suggestedMax: new Date(),
          ...(this.chartFromTime) && { min: this.chartFromTime },
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          id: 'y-axis-0',
          scaleLabel: {
            display: true,
            labelString: getConfigEnv('OPERATOR_LOWERCASED') === 'swn' || getConfigEnv('OPERATOR_LOWERCASED') === 'caerus' || getConfigEnv('OPERATOR_LOWERCASED') === 'pdc' ? 'Gas Rate' : 'Oil Rate & Water Rate',
            fontColor: '#ffffff90',
          },
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 2,
            fontStyle: 'bold',
            min: 0,
          },
        },
        {
          position: 'right',
          type: 'linear',
          id: 'y-axis-1',
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            color: 'rgba(255, 255, 255, 0.3)',
            fontColor: '#ffffff90',
            labelString: getConfigEnv('OPERATOR_LOWERCASED') === 'swn' || getConfigEnv('OPERATOR_LOWERCASED') === 'caerus' || getConfigEnv('OPERATOR_LOWERCASED') === 'pdc' ? 'Oil Rate & Water Rate' : 'Gas Rate',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 3,
            fontStyle: 'bold',
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      backgroundColor: '#fff',
      titleFontColor: '#8C8FB0',
      bodyFontColor: '#000750',
      bodyFontStyle: 'bold',
      titleAlign: 'center',
      bodyAlign: 'center',
      xPadding: 5,
      yPadding: 5,
      callbacks: {
        title: (tooltipItems) => tooltipItems[0].xLabel.slice(5).replace(/-/g, '/'),
        label: (tooltipItems) => `${tooltipItems.yLabel.toFixed()}
          ${this.chartData.datasets[tooltipItems.datasetIndex].unit || ''}`,
      },
    },
    onClick: (evt) => {
      const chart = (this.$refs.chart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const date = this.chartData.labels[item[0]._index];
        // @ts-ignore
        this.$eventBus.$emit(WELL_CLICK_EVENT, date);
        // @ts-ignore
        this.$eventBus.$emit(CHART_CLICK_EVENT, date);
      }
    },
    annotation: {
      drawTime: 'afterDatasetsDraw',
      events: ['onMouseenter'],
      annotations: [
      ],
    },
  }

  	get getWellHistory() {
	  return workflowModule.wellHistory;
  }

@Debounce(1000)
  onWellHistoryUpdated(history = this.getWellHistory) {
    this.chartOptions.annotation.annotations = [];
    // this.dataUpdating = true;
    if (this.showWellEvents) {
      history.forEach((data, i) => {
        if (data && !data.hideEvent && data.time && data.time.split('T')) {
          const anotationObj: any = {
            type: 'line',
            // optional drawTime to control layering, overrides global drawTime setting
            drawTime: 'afterDatasetsDraw',
            // optional annotation ID (must be unique)
            id: `a-line-${i}`,
            // set to 'vertical' to draw a vertical line
            mode: 'vertical',
            // ID of the scale to bind onto
            scaleID: 'x-axis-0',
            // Data value to draw the line at
            value: data.time.split('T')[0],
            // Line color
            borderColor: 'white',
            // Line width
            borderWidth: 1,
            label: {
            // Background color of label, default below
              backgroundColor: 'rgb(77, 192, 196)',
              // Font size of text, inherits from global
              fontSize: 10,
              // Font style of text, default below
              fontStyle: 'bold',
              // Font color of text, default below
              fontColor: '#fff',
              // Radius of label rectangle, default below
              cornerRadius: 6,
              // Anchor position of label on line, can be one of: top, bottom, left, right, center. Default below.
              position: 'top',
              // Whether the label is enabled and should be displayed
              enabled: true,
              // Text to display in label - default is null
              content: 'E',
            },
            events: ['mouseenter, click'],
            // Mouse event handlers - be sure to enable the corresponding events in the
            // annotation events array or the event handler will not be called.
            // See https://developer.mozilla.org/en-US/docs/Web/Events for a list of
            // supported mouse events.
            onMouseenter(e) { console.log(e); },
            onMouseover(e) {},
            onMouseleave(e) {},
            onMouseout(e) {},
            onMousemove(e) {},
            onMousedown(e) {},
            onMouseup(e) {},
            onClick: (evt) => {
              evt.preventDefault();
              // console.log(data);
              // @ts-ignore
              this.$eventBus.$emit(WELL_CLICK_EVENT, data);
            },
            onDblclick(e) {},
            onContextmenu(e) {},
            onWheel(e) {},
          };
          // eslint-disable-next-line prefer-destructuring
          const annotations: any = this.chartOptions.annotation.annotations;
          annotations.push(anotationObj);
          Object.assign(this.chartOptions, {
            annotation: {
              events: ['click'],
              annotations,
            },
          });
        }
      });
    }
    setTimeout(() => {
      this.zoomUpdated += 1;
    }, 100);
  }

get tasq() {
  return tasqsListModule.activeTasq;
}

  @Watch('isChartLoaded', {
    immediate: true,
  })
onChartLoadedChange() {
  this.dataUpdating = true;
  if (!this.productionData.data?.length) return;
  // Oil rate is 0
  // Water rate is 1
  // Gas rate is 2
  if (this.chartData.datasets[6]) {
    this.chartData.datasets[6].yAxisID = 'y-axis-1';
    this.chartData.datasets[7].yAxisID = 'y-axis-1';
    this.chartData.datasets[8].yAxisID = 'y-axis-1';
    this.chartData.datasets[6].label = 'Oil rate target';
    // this.chartData.datasets[1].borderColor = "rgba(44,230,194,0.1)" // Target upper band
    // this.chartData.datasets[2].borderColor = "transparent" // Target lower band
    // this.chartData.datasets[2].backgroundColor = "rgba(44,230,194,0.25)" // Target lower band
    // this.chartData.datasets[3].borderColor = "#2CE6C2" // Dashed target band
    // this.chartData.datasets[3].backgroundColor = "rgba(44,230,194,0.09)" // Dashed target band
    this.chartData.datasets[7].borderColor = 'rgba(44,230,194,0.1)'; // Target upper band
    this.chartData.datasets[8].borderColor = 'transparent'; // Target lower band
    this.chartData.datasets[8].backgroundColor = 'rgba(44,230,194,0.25)'; // Target lower band
    this.chartData.datasets[6].borderColor = '#2CE6C2'; // Dashed target band
    // this.chartData.datasets[3].backgroundColor = "rgba(44,230,194,0.09)" // Dashed target band
    // Upper thresh is index 6
    // Reference is index 7
    // Lower thres is index 8
  }
  if (getConfigEnv('OPERATOR_LOWERCASED') === 'swn' || getConfigEnv('OPERATOR_LOWERCASED') === 'caerus' || getConfigEnv('OPERATOR_LOWERCASED') === 'pdc') {
    this.chartData.datasets[3].label = 'Gas rate target';
    // this.chartData.datasets[0].borderColor = "#f55d8b"
    // this.chartData.datasets[0].backgroundColor = "#f55d8b"
    this.chartData.datasets[3].yAxisID = 'y-axis-0';
    this.chartData.datasets[4].yAxisID = 'y-axis-0';
    this.chartData.datasets[5].yAxisID = 'y-axis-0';
    this.chartData.datasets[4].borderColor = 'rgba(245, 93, 139 ,0.1)'; // Target upper band
    this.chartData.datasets[5].borderColor = 'transparent'; // Target lower band
    this.chartData.datasets[5].backgroundColor = 'rgba(245, 93, 139,0.25)'; // Target lower band
    this.chartData.datasets[3].borderColor = '#f55d8b'; // Dashed target band
    // this.chartData.datasets[3].backgroundColor = "rgba(245, 93, 139,0.09)" // Dashed target band
  } else {
    // this.chartData.datasets.splice(7, 1);
    // this.chartData.datasets.splice(8, 1);
    //  this.chartData.datasets.splice(7, 1);
  }
  // // Oil rate is 0
  // // Water rate is 1
  // // Gas rate is 2
  //
  if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
    this.chartData.datasets[0].data = this.productionData.data[0];
    this.chartData.datasets[1].data = this.productionData.data[1];
    this.chartData.datasets[2].data = this.productionData.data[2];
    this.chartData.datasets[4].data = this.productionData.data[6]; // Upper oil rate thresh
    this.chartData.datasets[3].data = this.productionData.data[7]; // Dotted target reference
    this.chartData.datasets[5].data = this.productionData.data[8]; // Lower oil rate thresh
    // [this.chartData.datasets[0].data] = this.productionData.data; // Oil rate 0
    // [, this.chartData.datasets[1].data] = this.productionData.data; // Water rate 1
    // [,, this.chartData.datasets[2].data] = this.productionData.data; // Gas rate 2
    // [,,, this.chartData.datasets[4].data] = this.productionData.data;
    // [,,,, this.chartData.datasets[3].data] = this.productionData.data;
    // [,,,,, this.chartData.datasets[5].data] = this.productionData.data;
    if (this.chartData.datasets[6]) {
      [,,,,,, this.chartData.datasets[7].data] = this.productionData.data;
      [,,,,,,, this.chartData.datasets[6].data] = this.productionData.data;
      [,,,,,,,, this.chartData.datasets[8].data] = this.productionData.data;
    }
    if (getConfigEnv('OPERATOR_LOWERCASED') === 'swn' || getConfigEnv('OPERATOR_LOWERCASED') === 'caerus' || getConfigEnv('OPERATOR_LOWERCASED') === 'pdc') {
      this.chartData.datasets[3].label = 'Gas rate target';
      // this.chartData.datasets[0].borderColor = "#f55d8b"
      // this.chartData.datasets[0].backgroundColor = "#f55d8b"
      this.chartData.datasets[3].yAxisID = 'y-axis-0';
      this.chartData.datasets[4].yAxisID = 'y-axis-0';
      this.chartData.datasets[5].yAxisID = 'y-axis-0';
      this.chartData.datasets[4].borderColor = 'rgba(245, 93, 139 ,0.1)'; // Target upper band
      this.chartData.datasets[5].borderColor = 'transparent'; // Target lower band
      this.chartData.datasets[5].backgroundColor = 'rgba(245, 93, 139,0.25)'; // Target lower band
      this.chartData.datasets[3].borderColor = '#f55d8b'; // Dashed target band
      // this.chartData.datasets[3].backgroundColor = "rgba(245, 93, 139,0.09)" // Dashed target band
    } else {
      // this.chartData.datasets.splice(7, 1);
      // this.chartData.datasets.splice(8, 1);
      //  this.chartData.datasets.splice(7, 1);
    }
  }
  // // Oil rate is 0
  // // Water rate is 1
  // // Gas rate is 2
  //
  if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
    this.chartData.datasets[0].data = this.productionData.data[0];
    this.chartData.datasets[1].data = this.productionData.data[1];
    this.chartData.datasets[2].data = this.productionData.data[2];
    this.chartData.datasets[4].data = this.productionData.data[6]; // Upper oil rate thresh
    this.chartData.datasets[3].data = this.productionData.data[7]; // Dotted target reference
    this.chartData.datasets[5].data = this.productionData.data[8]; // Lower oil rate thresh
    // [this.chartData.datasets[0].data] = this.productionData.data; // Oil rate 0
    // [, this.chartData.datasets[1].data] = this.productionData.data; // Water rate 1
    // [,, this.chartData.datasets[2].data] = this.productionData.data; // Gas rate 2
    // [,,, this.chartData.datasets[4].data] = this.productionData.data;
    // [,,,, this.chartData.datasets[3].data] = this.productionData.data;
    // [,,,,, this.chartData.datasets[5].data] = this.productionData.data;
    if (this.chartData.datasets[6]) {
      [,,,,,, this.chartData.datasets[7].data] = this.productionData.data;
      [,,,,,,, this.chartData.datasets[6].data] = this.productionData.data;
      [,,,,,,,, this.chartData.datasets[8].data] = this.productionData.data;
    }
  } else {
    [this.chartData.datasets[0].data] = this.productionData.data; // Oil rate 0
    [, this.chartData.datasets[1].data] = this.productionData.data; // Water rate 1
    [,, this.chartData.datasets[2].data] = this.productionData.data; // Gas rate 2
    [,,, this.chartData.datasets[4].data] = this.productionData.data;
    [,,,, this.chartData.datasets[3].data] = this.productionData.data;
    [,,,,, this.chartData.datasets[5].data] = this.productionData.data;
    if (this.chartData.datasets[6]) {
      [,,,,,, this.chartData.datasets[7].data] = this.productionData.data;
      [,,,,,,, this.chartData.datasets[6].data] = this.productionData.data;
      [,,,,,,,, this.chartData.datasets[8].data] = this.productionData.data;
    }
  }
  this.chartData.labels = this.productionData.time;
  this.xAxisRange = [this.chartFromTime, this.productionData.time[this.productionData.time.length - 1]];
  if (tasqsListModule.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE && this.defermentLabelingData) {
    const mappedDataset = this.defermentLabelingData && this.defermentLabelingData.dataset ? this.defermentLabelingData.dataset.filter((i) => i.Include).map((i) => i.date) : [];
	  const index = tasqProductionDataChartModule.defermentType == 'oil_rate' ? 0 : 2;
    this.chartData.datasets[index].pointBackgroundColor = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? '#2CE6C2' : 'transparent'));
    this.chartData.datasets[index].pointRadius = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? 5 : 0));
    if (getConfigEnv('OPERATOR_LOWERCASED') === 'swn' || getConfigEnv('OPERATOR_LOWERCASED') === 'caerus' || getConfigEnv('OPERATOR_LOWERCASED') === 'pdc') {
      this.chartData.datasets[0].pointBackgroundColor = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? '#2CE6C2' : 'transparent'));
      this.chartData.datasets[0].pointRadius = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? 5 : 0));
      this.chartData.datasets[2].pointBackgroundColor = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? '#2CE6C2' : 'transparent'));
      this.chartData.datasets[2].pointRadius = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? 5 : 0));
    }
  }
  const p1 = this.chartData.datasets[3];
  const p2 = this.chartData.datasets[4];
  const p3 = this.chartData.datasets[5];
  const p4 = this.chartData.datasets[6];
  const p5 = this.chartData.datasets[7];
  const p6 = this.chartData.datasets[8];
  this.chartData.datasets[3] = p4;
  this.chartData.datasets[4] = p5;
  this.chartData.datasets[5] = p6;
  this.chartData.datasets[6] = p1;
  this.chartData.datasets[7] = p2;
  this.chartData.datasets[8] = p3;
  // this.chartData.datasets[3] = p4
  if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
    this.chartData.datasets.splice(3, 1);
    this.chartData.datasets.splice(3, 1);
    this.chartData.datasets.splice(3, 1);
	  this.chartData.datasets[0].yAxisID = 'y-axis-0';
    this.chartData.datasets[1].yAxisID = 'y-axis-0';
    this.chartData.datasets[2].yAxisID = 'y-axis-1';
    this.chartData.datasets[3].yAxisID = 'y-axis-0';
    this.chartData.datasets[4].yAxisID = 'y-axis-0';
    this.chartData.datasets[5].yAxisID = 'y-axis-0';
  } else {
    this.chartData.datasets.splice(3, 1);
    this.chartData.datasets[1].yAxisID = 'y-axis-1';
    this.chartData.datasets.splice(3, 1);
    this.chartData.datasets.splice(3, 1);
  }

  if (this.productionData && this.productionData.time && this.chartData.datasets[3] && this.chartData.datasets[3].label) {
    const targetKey = this.chartData.datasets[3].label.includes('Gas') ? 'upperDataTime' : 'upperDataOilRateTime';
    if (targetKey && this.productionData[targetKey] && this.productionData[targetKey][0]) {
      const indexForTarget = this.productionData.time.findIndex((t) => t === this.productionData[targetKey][0]);

      if (indexForTarget > 10) {
        console.log('Fixed target');
        this.chartData.datasets[3].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[3].data);
        this.chartData.datasets[4].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[4].data);
        this.chartData.datasets[5].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[5].data);
      }
    }
  }
  this.onWellHistoryUpdated();

  let dataArray = this.chartData.datasets.map((dataSet) => {
    if (dataSet && dataSet.data && dataSet.data.length && dataSet.yAxisID === 'y-axis-0') {
      return dataSet.data.map((i) => Number(i));
    }
    return [];
  });
  dataArray = (Array.prototype.concat.apply([], dataArray));
  // const dataArray = (this.chartData.datasets[3].data.map((i) => Number(i)));
  const min = 0;
  const max = Math.max(...dataArray);
  // eslint-disable-next-line radix
  if (Number.isFinite(Number((min).toFixed(0))) && Number.isFinite(Number(max.toFixed(0)))) {
    this.minYaxis = 0;
    // eslint-disable-next-line radix
    this.maxYaxis = Number(max.toFixed(0)) < 0 ? 1 : Number((Number(max.toFixed(0)) + (Number(max.toFixed(0)) / 100) * 10).toFixed(0));
    const range = [this.minYaxis, this.maxYaxis];
    this.yAxisRange = range;
  }
  this.dataUpdating = false;
  this.$eventBus.$on('x-axis-drag-end', (xAxisRange) => {
    const dateHours = xAxisRange.map((s) => s.split('T'));
    // console.log(dateHours);
    // console.log(this.chartData.labels);
    // console.log( this.signalData.time);
    const start = this.productionData.time.find((l) => l.includes(dateHours[0][0]));
    const end = this.productionData.time.find((l) => l.includes(dateHours[1][0]));
    if (end && start) {
      this.xAxisRange = [end, start];
    }
    this.zoomUpdated += 1;
  });
}

    @Debounce(500)
  @Watch('xAxisRange')
  updatexAxisRangeValue(value) {
    // this.signalVisible = true;
    this.zoomUpdated += 1;
    // console.log(value);
    Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });
    // @ts-ignore
  }

    beforeDestroy() {
      this.$eventBus.$off('x-axis-drag-end');
    }

@Debounce(1500)
    xAxisDragEnd() {
      this.$eventBus.$emit('x-axis-drag-end', this.xAxisRange);
    }

  @Debounce(500)
  @Watch('yAxisRange')
updateMaxRangeValue(value) {
  // this.signalVisible = true;
  this.zoomUpdated += 1;
  Object.assign(this.chartOptions.scales.yAxes[0].ticks, {
    max: value[1],
    min: value[0],
  });
}

  @Watch('showWellEventsLocal')
  onUpdateShowWellEvents(data) {
    tasqProductionDataChartModule.setShowWellEvents(data);
    this.onWellHistoryUpdated();
    // this.$eventBus.$emit('show-well-events-local', data)
  }
}
